import React from "react"
import tw, { styled } from "twin.macro"
import { PrimaryButton as PrimaryButtonBase } from "./misc/Buttons.jsx"
import { SectionHeading } from "./misc/Headings.jsx"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Container = tw.div`relative h-full`
const Title = tw(SectionHeading)`w-full text-center pt-10`
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-0 lg:mx-auto p-0 lg:p-16`

const TextColumn = tw.div` text-center md:text-left lg:w-6/12 py-8 lg:px-8 lg:py-0`
const Description = styled.div`
  ${tw`text-left  md:text-base lg:text-lg font-medium leading-relaxed `}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10 border-b-2`}
  }
  h2 {
    ${tw`text-base font-bold mt-6`}
  }
  h3 {
    ${tw`text-sm font-bold mt-6`}
  }
`

const FormColumn = tw.div` text-left lg:w-6/12 p-8 bg-gray-100 rounded-4xl border-2`

const Form = tw.form`text-lg flex flex-col w-full mx-auto md:mx-0 `
const Checkbox = tw.input`font-medium `
const Input = tw.input`text-base my-2 first:mt-0 border-b-2 py-2 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = tw.textarea`text-base my-2 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500 h-48`
const PrivacyPolicy = tw.a`text-center mt-4 text-base`
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-4 text-lg`

const Tel = tw.a`text-center my-4 text-xl inline-block`
const Information = tw.div`text-center mb-8 text-base`

export default () => {
  const description = (
    <>
      AWS 導入、活用に関するお悩みを、
      <br />
      ”無料” でAPN アドバンスドティアサービスのRosso に
      <br />
      相談いただけます。
      <br /> <br />
      ■例えば、こんな方へお勧めです。
      <h3>
        ✔クラウドの導入を検討しているが、何から手を付ければ良いのか分からない...
        <br />
        ✔サーバのメンテナンスに手間が掛かり、リソースが足りない...
        <br />
        ✔AWSの運用コストを削減したいが、何処に相談すれば良いのか分からない...
        <br />
        ✔通常の費用よりもAWSを安く利用したい...
      </h3>
      <br />
      その他、ご相談もお気軽にお問合せ下さい。
      <h2>
        【開催日時】 平日 9:30~18:30 のうち1時間程度
        <br />
        【参加費】 無料
        <br />
        【会場】 オンラインにて（会議ツールは貴社指定も可能です）
        <br /> <br />
        ※各社様1回限りとなります
      </h2>
      <br />
      ■Rossoのサービスもご紹介致します。
    </>
  )

  return (
    <Container>
      <Title>AWS オンライン相談会</Title>
      <TwoColumn>
        <TextColumn>
          <Description>{description}</Description>
          <Link to="/cloud/">
            <StaticImage src="../images/consult-cloud-1.png" />
          </Link>
        </TextColumn>
        <FormColumn>
          <Form
            name="consultcloud"
            action="/thank-you/"
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <Input type="hidden" name="form-name" value="consultcloud" />
            <Input type="hidden" name="bot-field" />
            <b>相談テーマ（※ 複数選択可）</b>
            <p>
              <Checkbox type="checkbox" name="Theme1" value="AWS 構築、移行" />
              AWS構築、移行
              <br />
              <Checkbox type="checkbox" name="Theme2" value="AWS 運用" />
              AWS運用
              <br />
              <Checkbox type="checkbox" name="Theme3" value="請求代行" />
              請求代行
              <br />
              <Checkbox type="checkbox" name="Theme4" value="IoT" />
              IoT
              <br />
              <Checkbox type="checkbox" name="Theme5" value="コスト削減" />
              コスト削減
              <br />
              <Checkbox type="checkbox" name="Theme6" value="テレワーク" />
              テレワーク
              <br />
              <Checkbox type="checkbox" name="Theme7" value="その他" />
              その他
              <br />
            </p>
            <Input
              type="text"
              name="subject"
              placeholder=" 会社名(必須)"
              required
            />
            <Input
              type="text"
              name="name"
              placeholder=" お名前(必須)"
              required
            />
            <Input
              type="email"
              name="email"
              placeholder=" 会社メールアドレス(必須)"
              required
            />
            <Input
              type="text"
              name="date"
              placeholder=" ご都合の良い時間や曜日"
            />
            <Textarea
              name="message"
              placeholder=" ご相談内容"
              maxlength="2000"
            />
            <PrivacyPolicy
              href="https://www.rosso-tokyo.co.jp/contact/consent/"
              target="_blank"
              rel="noopener noreferrer"
            >
              個人情報の取り扱いについて
            </PrivacyPolicy>
            <SubmitButton type="submit">
              個人情報の取り扱いに同意し送信
            </SubmitButton>
          </Form>
        </FormColumn>
      </TwoColumn>
      <Information>
        <Tel href="tel:0364279037">☎︎ 03-6427-9037</Tel>
        <div>(サクッとAI､サクッとクラウド担当宛)</div>
        <div>営業時間平⽇9:30~18:30 (⼟⽇祝休み)</div>
      </Information>
    </Container>
  )
}
