import React from "react"
import AnimationRevealPage from "../components/helpers/AnimationRevealPage.jsx"
import Header from "../components/Header.jsx"
import Footer from "../components/Footer.jsx"
import ConsultCloud from "../components/ConsultCloud.jsx"
import SEO from "../components/misc/SEO.jsx"

export default () => {
  return (
    <AnimationRevealPage disabled>
      <SEO title="サクッとクラウドオンライン相談会" />
      <Header />
      <ConsultCloud />
      <Footer />
    </AnimationRevealPage>
  )
}
